.infurnia-org-logo {
    max-width: 120px;
    max-height: calc(100% - 1rem);
    width: 100%;
    object-fit: contain
}

.infurnia-navbar {
    display: flex;
    height: var(--infurnia-navbar-heignt);
    width: 100%;
    /* Light Mode Properties */
    /* box-shadow: 0px 1px 1px rgba(0 0, 0, 0.25); */
    border-bottom: 1px solid rgba(0 0, 0, 0.25);
    background: var(--layer-main-bg);
    color: var(--layer-main-text-color);
    padding: 0rem 1.5rem;
}

.infurnia-sidebar{
    width: var(--infurnia-sidebar-width);
    box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.10);
    background: var(--layer-main-bg);
    text-align: left;
    transition: width 0.3s ease-in-out;
}

/* @media screen and (max-width: 641px) {
    :root{
        --infurnia-sidebar-width: 0px;
    }
    .infurnia-sidebar{
        transform: translateX(-100%);
        overflow: hidden;
    }
} */

.inf-sidebar-actions{
    flex: 1;
    padding: 1.5rem 1.5rem;
}

#new_project_button{
    background-color: #0078ff;
    width: 100%;
    border: none;
    outline: none;
    box-shadow: none;
    font-size: 12px;
}

.inf-sidebar-options-container{
    padding: 0.375rem;
    border-radius: 0.375rem;
    border: 1px solid var(--light-border-color);
}

.inf-sidebar-option{
    height: 2rem;
    border-radius: 0.25rem;
    padding: 0px 0.5rem;
    display: flex;
    align-items: center;
    cursor: pointer;
    font-weight: 300;
    font-size: 12px;
}

.inf-sidebar-options-container .inf-sidebar-option:not(:last-child){
    margin-bottom: var(--spacing-1);
}

.inf-sidebar-option.sidebar-option-active{
    background-color: var(--inf-primary-blue-hover-color) !important;
}

.inf-sidebar-option:hover{
    background-color: var(--inf-theme-gray-200);
}

.inf-sidebar-profile{
    padding: 2rem 1.5rem;
    border-bottom: 1px solid var(--light-border-color);
}

.inf-sidebar-helpmenu{
    padding: 1rem 1.5rem;
    border-top: 1px solid var(--light-border-color);
    display: flex;
    flex-direction: column;
    gap: 12px;
    font-size: 12px;
    color: var(--inf-theme-gray-600)
}

.inf-sidebar-helpmenu a{
    color: var(--inf-theme-gray-600)
}

.hover_blue:hover a{
    color: #0078ff;
}

.infurnia-card-container{
    width: calc( 100% - var(--infurnia-sidebar-width) );
    overflow: auto;
}

.infurnia-main-page{
    width: 100%;
    height: 100vh;
    overflow: hidden;
    background: var(--layer-secondary-bg);
}

.infurnia-navbar-icons{
    /* margin-bottom: 0px; */
    /* padding:8px; */
    display:flex;
    align-items:center;
    justify-content: center;
    height:40px;
    border: none;
    background: none;
    outline: none;
}