.inf_nav_tabs_blue_background {
    margin: 4px 0px;
    padding: 0px 4px;
    font-size: 14px;
    color: #676878;
    cursor: pointer;
    border-bottom: 2px solid #E4E7EC;
}

.inf_nav_tabs_blue_background.active {
    margin: 4px 0px;
    padding: 0px 4px;
    font-size: 14px;
    color: #676878;
    cursor: pointer;
    background: #E8EFFD;
}


.inf_nav_tabs_blue_background_1 {
    padding: 4px 16px;
    font-size: 14px;
    color: #676878;
    cursor: pointer;
}

.inf_nav_tabs_blue_background_1.active {
    font-weight: 700;
    color: #3A63D4;
    background: rgba(16, 112, 202, 0.09);
}
