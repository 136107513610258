/* @import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap'); */

.ref_level_dropdown_menu{
    width:240px;
    max-height:240px;
    overflow-y:auto; 
    position:absolute !important; 
    left:0 !important; 
    top:40px !important;
}

.panel_green_btn{
    border: 0px;
    padding: 8px 16px;
    font-size: 12px;
    background-color: #0078FF;
    color: rgba(255, 255, 255);
    text-transform:capitalize;
    /* letter-spacing: 0; */
    /* will-change: box-shadow, transform;
    transition: box-shadow 0.2s cubic-bezier(0.4, 0, 1, 1), background-color 0.2s cubic-bezier(0.4, 0, 0.2, 1), color 0.2s cubic-bezier(0.4, 0, 0.2, 1);
    box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 20%), 0 1px 5px 0 rgb(0 0 0 / 12%); */
}

.panel_green_btn:hover{
    background-color: #0078FF !important;
}

.panel_color_green_arrow{
    color: #00aa9a !important;
}

.panel_green_btn:focus {
    background-color: #0078FF!important;
    border: 0!important;
    outline: 0 !important;
}
.panel_green_btn:focus-visible {    
    outline: 0 !important;
}

.panel_disabled{
    opacity:0.5;
    cursor:not-allowed !important;
    pointer-events: none;
}

.panel_disabled_opacity{
    opacity:0.5;
    cursor:not-allowed !important;
}

.hover_enabled_green_border:hover{
    background-color: #F5F6F8 !important;
    border-right: 3px solid #0078FF !important;
}

.lines2_elipsis{
    /* display: block; */
    display: -webkit-box !important;
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    /* autoprefixer: off */
}

.lines1_elipsis{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.test_cursor {
  cursor: url('/resources/toolbar_icons_new/extrude.svg'), crosshair !important;
}

.underline_onhover:hover{
  text-decoration: underline;
}

.bold_onhover:hover{
  font-weight: 700;
}

.blue_highlight_focuswithin:focus-within{
  /* box-shadow: 0 0 0 0.1rem rgb(0 123 255 / 25%); */
  border: 1px solid #0078FF !important;
}


/* .toggleSwitch span span {
    display: none;
  }
  
  @media only screen {
    .toggleSwitch {
      display: inline-block;
      height: 18px;
      position: relative;
      overflow: visible;
      padding: 0;
      margin-left: 50px;
      cursor: pointer;
      width: 40px
    }
    .toggleSwitch * {
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
    }
    .toggleSwitch label,
    .toggleSwitch > span {
      line-height: 20px;
      height: 20px;
      vertical-align: middle;
    }
    .toggleSwitch input:focus ~ a,
    .toggleSwitch input:focus + label {
      outline: none;
    }
    .toggleSwitch label {
      position: relative;
      z-index: 3;
      display: block;
      width: 100%;
    }
    .toggleSwitch input {
      position: absolute;
      opacity: 0;
      z-index: 5;
    }
    .toggleSwitch > span {
      position: absolute;
      left: -50px;
      width: 100%;
      margin: 0;
      padding-right: 50px;
      text-align: left;
      white-space: nowrap;
    }
    .toggleSwitch > span span {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 5;
      display: block;
      width: 50%;
      margin-left: 50px;
      text-align: left;
      font-size: 0.9em;
      width: 100%;
      left: 15%;
      top: -1px;
      opacity: 0;
    }
    .toggleSwitch a {
      position: absolute;
      right: 50%;
      z-index: 4;
      display: block;
      height: 100%;
      padding: 0;
      left: 2px;
      width: 18px;
      background-color: #fff;
      border: 1px solid #CCC;
      border-radius: 100%;
      -webkit-transition: all 0.2s ease-out;
      -moz-transition: all 0.2s ease-out;
      transition: all 0.2s ease-out;
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
    }
    .toggleSwitch > span span:first-of-type {
      color: #ccc;
      opacity: 1;
      left: 45%;
    }
    .toggleSwitch > span:before {
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      left: 50px;
      top: -2px;
      background-color: #fafafa;
      border: 1px solid #ccc;
      border-radius: 30px;
      -webkit-transition: all 0.2s ease-out;
      -moz-transition: all 0.2s ease-out;
      transition: all 0.2s ease-out;
    }
    .toggleSwitch input:checked ~ a {
      border-color: #fff;
      left: 100%;
      margin-left: -8px;
    }
    .toggleSwitch input:checked ~ span:before {
      border-color: #0097D1;
      box-shadow: inset 0 0 0 30px #0097D1;
    }
    .toggleSwitch input:checked ~ span span:first-of-type {
      opacity: 0;
    }
    .toggleSwitch input:checked ~ span span:last-of-type {
      opacity: 1;
      color: #fff;
    }
    .toggleSwitch.large {
      width: 60px;
      height: 27px;
    }
    .toggleSwitch.large a {
      width: 27px;
    }
    .toggleSwitch.large > span {
      height: 29px;
      line-height: 28px;
    }
    .toggleSwitch.large input:checked ~ a {
      left: 41px;
    }
    .toggleSwitch.large > span span {
      font-size: 1.1em;
    }
    .toggleSwitch.large > span span:first-of-type {
      left: 50%;
    }
    .toggleSwitch.xlarge {
      width: 80px;
      height: 36px;
    }
    .toggleSwitch.xlarge a {
      width: 36px;
    }
    .toggleSwitch.xlarge > span {
      height: 38px;
      line-height: 37px;
    }
    .toggleSwitch.xlarge input:checked ~ a {
      left: 52px;
    }
    .toggleSwitch.xlarge > span span {
      font-size: 1.4em;
    }
    .toggleSwitch.xlarge > span span:first-of-type {
      left: 50%;
    }
  }
*/

.panel_navigator_left{
  background-color: var(--panel_tree_container_background);
  /* border-top: 1px solid #C5C7C7; */
}

.panel_navigator_container{
  display: flex;
  flex-direction: column;
  position: absolute;
  left: 270px;
  top: 136px;
  background-color: var(--panel_tree_container_background);
  /* max-height: calc(100% - 172px); */
  overflow: auto;
  z-index: 2;
}

.panel_navigator_item{
  writing-mode: vertical-rl;
  border-bottom: 2px solid #788394;
  width: 32px;
  padding: 12px 8px;
  font-size: 12px;
  font-family: 'DM Sans', sans-serif;
  position: relative;
  cursor: pointer;
  min-height: max-content;
}

.disabled_panel_navigator_item{
  writing-mode: vertical-rl;
  border-bottom: 2px solid #788394;
  width: 32px;
  padding: 12px 8px;
  font-size: 12px;
  font-family: 'DM Sans', sans-serif;
  position: relative;
  cursor: pointer;
  min-height: max-content;
  opacity: 0.5;
}

.panel_navigator_item:hover{
  background-color: rgba(217, 217, 217, 0.5);
}

.arrow_down_panel_navigator {
  width: 0; 
  height: 0; 
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  
  border-top: 3px solid #788394;
  position: absolute;
  top: 0;
  /* left = (width - border-right - border-left)/2*/
  left: 12px;
}

.selected_panel_navigator_item{
  writing-mode: vertical-rl;
  border-bottom: 2px solid #788394;
  width: 32px;
  padding: 12px 8px;
  font-size: 12px;
  font-family: 'DM Sans', sans-serif;
  position: relative;
  font-weight: bold;
  color: #3A63D4;
  background-color: #E2EFFD;
  cursor: pointer;
  min-height: max-content;
}

.tree_panel_navigator{
  background-color: var(--panel_tree_container_background);
  height: 43px;
  min-height: 43px;
  border-bottom: 1px solid #C5C7C7;
  border-top: 1px solid #C5C7C7;
  display: flex;
  align-items: center;
  width: 32px;
  /* position: fixed;
  z-index: 2; */
}

.right_angle_left_panel_navigator{
  width: 0; 
  height: 0; 
  border-left: 10px solid #788394;
  /* border-right: 6px solid transparent;
  border-top: 6px solid transparent; */
  
  border-top: 10px solid transparent;
  position: absolute;
  bottom: 0;
  
}

#panel_scroll_container {
  font-size: 12px;
  border-right: 2px solid #788394;
  font-family: 'DM Sans', sans-serif;
  color: #676878;
}

#panel_scroll_container::-webkit-scrollbar {
  width: 0px;
}

/* .selected_panel_navigator_item .arrow_down_panel_navigator {
  animation:  vibrate .5s ease-in-out;
}

@keyframes vibrate {
  from {
    transform: translateY(5px);
  }
  to {
    transform: translateY(0);
  }
} */

.panel_navigator_overflow_container{
  /* position: absolute; */
  /* bottom: 0; */
  /* height: 10px; */
}

.panel_navigator_overflow{
  /* display: flex; */
  /* position: relative; */
  display: none;
  background-color: var(--panel_tree_container_background);
}

.panel_navigator_overflow_container:hover .panel_navigator_overflow{
  display: flex;
  position: fixed;
  left: 302px;
  /* left: 10; */
  /* bottom: 50px; */
}

.panel_navigator_item_overflow{
  border-right: 2px solid #788394;
  height: 32px;
  padding: 8px 12px;
  font-size: 12px;
  font-family: 'DM Sans', sans-serif;
  /* position: relative; */
  cursor: pointer;
  /* min-height: max-content; */
  min-width: max-content;
}

.selected_panel_navigator_item_overflow{
  border-right: 2px solid #788394;
  height: 32px;
  padding: 8px 12px;
  font-size: 12px;
  font-family: 'DM Sans', sans-serif;
  /* position: relative; */
  cursor: pointer;
  /* min-height: max-content; */
  font-weight: bold;
  color: #3A63D4;
  background-color: #E2EFFD;
  min-width: max-content;
}

.disabled_panel_navigator_item_overflow{
  border-right: 2px solid #788394;
  height: 32px;
  padding: 8px 12px;
  font-size: 12px;
  font-family: 'DM Sans', sans-serif;
  /* position: relative; */
  cursor: pointer;
  /* min-height: max-content; */
  min-width: max-content;
  opacity: 0.5;
}

.panel_navigator_spread{
  display: flex;
  align-items: center;
  line-height: 10px;
  justify-content: center;
  padding-bottom: 6px;
}

.panel_heading_text{
  font-weight: 700;
  font-family: 'DM Sans', sans-serif;
  vertical-align: middle;
  font-size: 16px;
}

.view_port_heading{
  text-align: left;
  font-weight: 700;
  font-size: 14px;
  font-family: 'DM Sans', sans-serif;
  font-style: italic;
  /* text-decoration: underline; */
  color: #A7A8B2;
}

.view_port_row{
  display: flex;
  /* height: 36px; */
  align-items: center;
}

.view_port_info_container .view_port_row:not(:last-child){
  margin-bottom: 12px;
}

.view_port_property{
  width: 80px;
  text-align: left;
  text-overflow: ellipsis;
  padding-right: 12px;
  font-size: 14px;
}

.view_port_shortcut{
  flex: 1;
  display: flex;
  align-items: center;
  /* padding-left: 12px; */
  height: 100%;
}

.view_port_icons {
  padding-right: 12px;
}

.view_port_container {
  margin-top: 8px !important;
  color: #676878;
}

.view_port_text_under_icon{
  font-size: 8px;
}

.view_port_info_text{
  font-size: 12px;
  text-align: left;
  color: #a7a8b3;
}

.panel_empty_state_text_container{
  font-size: 14px;
}

.view_port_text_style_2{
  font-size: 14px;
  /* height: 100%; */
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

.view_port_icon_text{
  font-size: 12px;
  height: 100%;
  display: flex;
  align-items: center;
  margin-left: 4px;
}

.panel_info_list{
  background-color:#DBDFE6;
  color:#A7A8B2;
  font-size:12px; 
  font-weight:500;
  padding-right: 12px;
  padding-bottom: 6px;
  padding-top: 6px;
  padding-left: 32px;
  text-align: left;
  border-radius: 4px;
}

.view_port_info_container{
  background-color: #EDEFF3;
  padding: 12px;
  margin-bottom: 16px;
  border-radius: 4px;
}

.sku_info_toottip_container{
  border-radius: 6px;
  padding: 8px 12px;
  /* width: 400px; */
  background-color: #ffffff;
  color: #0078FF;
}
