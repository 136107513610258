.inf-flex {
    display: flex;
}

.inf-flex-col {
    flex-direction: column;
}

.inf-ml-auto {
    margin-left: auto;
}

.inf-justify-end {
    justify-content: end;
}

.inf-justify-center {
    justify-content: center;
}

.inf-justify-between {
    justify-content: space-between;
}

.inf-items-center {
    align-items: center;
}

.inf-width-full {
    width: 100%;
}

.inf-height-full {
    height: 100%;
}

/* Flex container for grid view */
.inf-flex-grid {
    display: flex;
    flex-wrap: wrap;
    gap: var(--inf-flex-grid-gap);
}

/* Utility classes for number of cards and spacing */
.inf-wrap-2 {
    --flex-basis: calc(50% - var(--inf-flex-grid-gap));
}

.inf-wrap-3 {
    --flex-basis: calc(33.3333% - var(--inf-flex-grid-gap));
}

.inf-wrap-4 {
    --flex-basis: calc(25% - var(--inf-flex-grid-gap));
}

.inf-wrap-5 {
    --flex-basis: calc(20% - var(--inf-flex-grid-gap));
}

/* Responsive utility classes */
@media screen and (min-width: 640px) {
    .inf-wrap-2-sm {
        --flex-basis: calc(50% - var(--inf-flex-grid-gap));
    }

    .inf-wrap-3-sm {
        --flex-basis: calc(33.3333% - var(--inf-flex-grid-gap));
    }

    .inf-wrap-4-sm {
        --flex-basis: calc(25% - var(--inf-flex-grid-gap));
    }

    .inf-wrap-5-sm {
        --flex-basis: calc(20% - var(--inf-flex-grid-gap));
    }
}

@media screen and (min-width: 768px) {
    .inf-wrap-2-md {
        --flex-basis: calc(50% - var(--inf-flex-grid-gap));
    }

    .inf-wrap-3-md {
        --flex-basis: calc(33.3333% - var(--inf-flex-grid-gap));
    }

    .inf-wrap-4-md {
        --flex-basis: calc(25% - var(--inf-flex-grid-gap));
    }

    .inf-wrap-5-md {
        --flex-basis: calc(20% - var(--inf-flex-grid-gap));
    }
}

@media screen and (min-width: 1024px) {
    .inf-wrap-2-lg {
        --flex-basis: calc(50% - var(--inf-flex-grid-gap));
    }

    .inf-wrap-3-lg {
        --flex-basis: calc(33.3333% - var(--inf-flex-grid-gap));
    }

    .inf-wrap-4-lg {
        --flex-basis: calc(25% - var(--inf-flex-grid-gap));
    }

    .inf-wrap-5-lg {
        --flex-basis: calc(20% - var(--inf-flex-grid-gap));
    }
}

@media screen and (min-width: 1280px) {
    .inf-wrap-2-xl {
        --flex-basis: calc(50% - var(--inf-flex-grid-gap));
    }

    .inf-wrap-3-xl {
        --flex-basis: calc(33.3333% - var(--inf-flex-grid-gap));
    }

    .inf-wrap-4-xl {
        --flex-basis: calc(25% - var(--inf-flex-grid-gap));
    }

    .inf-wrap-5-xl {
        --flex-basis: calc(20% - var(--inf-flex-grid-gap));
    }
}

@media screen and (min-width: 1536px) {
    .inf-wrap-2-2xl {
        --flex-basis: calc(50% - var(--inf-flex-grid-gap));
    }

    .inf-wrap-3-2xl {
        --flex-basis: calc(33.3333% - var(--inf-flex-grid-gap));
    }

    .inf-wrap-4-2xl {
        --flex-basis: calc(25% - var(--inf-flex-grid-gap));
    }

    .inf-wrap-5-2xl {
        --flex-basis: calc(20% - var(--inf-flex-grid-gap));
    }
}

/* Media queries for responsiveness */
@media screen and (max-width: 600px) {
    .inf-flex-grid {
        gap: 0;
        /* No gap between items on small screens */
    }

    .inf-wrap-2,
    .inf-wrap-3,
    .inf-wrap-4,
    .inf-wrap-5,
    .inf-wrap-2-md,
    .inf-wrap-3-md,
    .inf-wrap-4-md,
    .inf-wrap-5-md,
    .inf-wrap-2-lg,
    .inf-wrap-3-lg,
    .inf-wrap-4-lg,
    .inf-wrap-5-lg
    .inf-wrap-2-xl,
    .inf-wrap-3-xl,
    .inf-wrap-4-xl,
    .inf-wrap-5-xl,
    .inf-wrap-2-2xl,
    .inf-wrap-3-2xl,
    .inf-wrap-4-2xl,
    .inf-wrap-5-2xl  {
        --flex-basis: calc(100% - var(--inf-flex-grid-gap));
    }
}

.inf-gap-0 {
    --inf-flex-grid-gap: 0rem;
    gap: var(--inf-flex-grid-gap, 0rem)
}

.inf-gap-1 {
    --inf-flex-grid-gap: 0.25rem;
    gap: var(--inf-flex-grid-gap, 0.25rem)
}

.inf-gap-2 {
    --inf-flex-grid-gap: 0.5rem;
    gap: var(--inf-flex-grid-gap, 0.5rem)
}

.inf-gap-3 {
    --inf-flex-grid-gap: 0.75rem;
    gap: var(--inf-flex-grid-gap, 0.75rem)
}

.inf-gap-4 {
    --inf-flex-grid-gap: 1rem;
    gap: var(--inf-flex-grid-gap, 1rem)
}

.inf-gap-5 {
    --inf-flex-grid-gap: 1.25rem;
    gap: var(--inf-flex-grid-gap, 1.25rem)
}

.inf-gap-6 {
    --inf-flex-grid-gap: 1.5rem;
    gap: var(--inf-flex-grid-gap, 1.5rem)
}

.inf-gap-7 {
    --inf-flex-grid-gap: 1.75rem;
    gap: var(--inf-flex-grid-gap, 1.75rem)
}

.inf-gap-8 {
    --inf-flex-grid-gap: 2rem;
    gap: var(--inf-flex-grid-gap, 2rem)
}

.inf-gap-9 {
    --inf-flex-grid-gap: 2.25rem;
    gap: var(--inf-flex-grid-gap, 2.25rem)
}

.inf-gap-10 {
    --inf-flex-grid-gap: 2.5rem;
    gap: var(--inf-flex-grid-gap, 2.5rem)
}

.inf-gap-11 {
    --inf-flex-grid-gap: 2.75rem;
    gap: var(--inf-flex-grid-gap, 2.75rem)
}

.inf-gap-12 {
    --inf-flex-grid-gap: 3rem;
    gap: var(--inf-flex-grid-gap, 3rem)
}

/* General styling for grid items */
.inf-flex-grid-item {
    flex-basis: var(--flex-basis);
    min-width: 200px;
}

/* Info classes for text alignment */
.inf-text-left {
    text-align: left;
}

.inf-text-center {
    text-align: center;
}

.inf-text-right {
    text-align: right;
}

.inf-text-justify {
    text-align: justify;
}

.inf-text-start {
    text-align: start;
}

.inf-text-end {
    text-align: end;
}

.inf-border{
    border: 1px solid var(--light-border-color);
}

.flex-1-mw {
    flex: 1;
    min-width: 1px;
}

.flex-2-mw {
    flex: 2;
    min-width: 1px;
}

.flex-3-mw {
    flex: 3;
    min-width: 1px;
}

.flex-4-mw {
    flex: 4;
    min-width: 1px;
}

.flex-5-mw {
    flex: 5;
    min-width: 1px;
}

.flex-6-mw {
    flex: 6;
    min-width: 1px;
}

.flex-7-mw {
    flex: 7;
    min-width: 1px;
}

.flex-8-mw {
    flex: 8;
    min-width: 1px;
}

.flex-9-mw {
    flex: 9;
    min-width: 1px;
}

.flex-10-mw {
    flex: 10;
    min-width: 1px;
}

.flex-11-mw {
    flex: 11;
    min-width: 1px;
}

.flex-12-mw {
    flex: 12;
    min-width: 1px;
}

.flex-1 {
    flex: 1;
}

.flex-2 {
    flex: 2;
}

.flex-3 {
    flex: 3;
}

.flex-4 {
    flex: 4;
}

.flex-5 {
    flex: 5;
}

.flex-6 {
    flex: 6;
}

.flex-7 {
    flex: 7;
}

.flex-8 {
    flex: 8;
}

.flex-9 {
    flex: 9;
}

.flex-10 {
    flex: 10;
}

.flex-11 {
    flex: 11;
}

.flex-12 {
    flex: 12;
}

.w-full {
    width: 100%;
}

.h-full {
    height: 100%;
}

.rounded-sm	{
    border-radius: 0.125rem;
}

.rounded	{
    border-radius: 0.25rem;
}

.rounded-md	{
    border-radius: 0.375rem;
}

.rounded-full	{
    border-radius: 9999px;
}
